<template>

  <div class="top-boxes">
    <div class="total-allocated box box-fw">
      <strong class="box-title">Total Allocated</strong>
      <div class="flex-box title-spaces total-values"><label class="flex-item">Budget</label><span class="dollar flex-item">{{ topBoxData.totalAllocatedBudget | formatThousands }} + GST</span></div>
      <div class="flex-box total-values"><label class="flex-item">Hours</label><span class="hours flex-item">{{ topBoxData.totalAllocatedHours | formatThousands }}</span></div>
    </div>
    <div class="color-boxes">
    <div class="used box">
      <strong class="box-title">Used</strong>
      <div class="flex-box title-spaces"><label class="flex-item">Budget:</label><span class="dollar flex-item">{{ topBoxData.usedBudget | formatThousands}}</span></div>
      <div class="flex-box"><label class="flex-item">Hours:</label><span class="hours flex-item">{{ topBoxData.usedHours | formatThousands}}</span></div>
    </div>
    <div class="remaining box">
      <strong class="box-title">Remaining</strong>
      <div class="flex-box title-spaces"><label class="flex-item">Budget:</label><span class="dollar flex-item">{{ topBoxData.remainingBudget | formatThousands}}</span></div>
      <div class="flex-box"><label class="flex-item">Hours:</label><span class="hours flex-item">{{ topBoxData.remainingHours | formatThousands}}</span></div>
    </div>
    <div class="normal-rate box">
      <strong class="box-title">Normal Rate</strong>
      <div class="dollar title-spaces">{{ normalRate }} + GST</div>
    </div>
    <div class="urgent-rate box">
      <strong class="box-title">Urgent Rate</strong>
      <div class="dollar title-spaces">{{ urgentRate }} + GST</div>
    </div>
  </div>
  </div>

</template>

<script>
export default {
  name: 'TopBoxes',
  props: {
    topBoxData: Object,
    normalRate: Number,
    urgentRate: Number,
  },
  filters: {
    formatThousands(number) {
      return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .top-boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 800px;
    width: 100%;
    margin: auto;
  }

  .box {
    border: solid 1px black;
    margin: 2em;
    width: 200px;
    height: 100px;
  }

  .box.box-fw {
    width: 100%;
  }

  .total-allocated {
    background: #E8E9EE;
    border: none;
    padding: 20px;
    height: auto;
    color: #7E7E7E;
    font-size: 17px;
    font-weight: bold;
  }

  .box {
    width: 100%;
  }

  .color-boxes {
    display: flex;
    flex-wrap: wrap;
  }

  .color-boxes>div {
    flex: 1 40%;
    box-sizing:border-box
  }

  .used {
    background: #4BB29D;
    color: white;
    padding: 20px;
    height: auto;
    border: none;
    font-weight: bold;
  }

  .remaining {
    background: #D8A820;
    color: white;
    padding: 20px;
    height: auto;
    border: none;
    font-weight: bold;
  }

  .normal-rate {
    background: #86AFFB;
    color: white;
    padding: 20px;
    height: auto;
    border: none;
    font-weight: bold;
  }

  .urgent-rate {
    background: #D46C4E;
    color: white;
    padding: 20px;
    height: auto;
    border: none;
    font-weight: bold;
  }

  .box-title {
    text-transform: uppercase;
  }

  .dollar:before {
    content: '$';
  }

  .hours:after {
    content: ' hrs';
  }

  .flex-box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .flex-item {
    flex: 0 50%;
  }

  .title-spaces {
    margin-top: 10px;
  }

  .total-values {
    width: 75%;
    margin: auto;
    padding-top: 10px;
  }
</style>
