<template>
  <div id="app">
    <h1>Support Puppy 🐶</h1>
    <h2>CCAMLR Support Hours Report</h2>

    <div class="update-date">
      <div class="latest-date"> <label class="data-as-of">Data as of:</label> <strong>{{ lastUpdate.toLocaleString("en-US", dateTimeOptions) }}</strong></div>
      <div class="btn"> <a href="#" v-on:click="this.refresh" class="update-btn">Update Data</a></div>
    </div>

    <div class="option"><select class="date-option"><option>{{ this.fromDate.toLocaleDateString("en-AU", options) }} - {{ this.toDate.toLocaleDateString("en-US", options) }}</option></select></div>

    <template v-if="!loading">
      <TopBoxes
        v-bind:top-box-data="topBoxData"
        :normal-rate="normalRate"
        :urgent-rate="urgentRate"
      ></TopBoxes>
      <SuppportChart v-bind:chart-data="this.chartData"></SuppportChart>
    </template>
    <template v-else> Loading... </template>
  </div>
</template>

<script>
import TopBoxes from "./components/TopBoxes.vue";
import SuppportChart from "./components/SuppportChart.vue";
import SupportPuppyApi from "./apiRequest/supportPuppyAPI";

export default {
  name: "SupportPuppy",
  components: {
    SuppportChart,
    TopBoxes,
  },
  data() {
      return {
        loading: false,
        fromDate: new Date(2020, 11, 1),
        toDate: new Date(2021, 1, 28),
        totalAllocatedBudget: 15590,
        totalAllocatedHours: 110,
        normalRate: 145,
        urgentRate: 217.5,
        lastUpdate: 0,
        chartData: {},
        topBoxData: {},
        urgentTag: 73676,
        dailyHours: [],
        usedBudget: [],
        options: { year: 'numeric', month: 'long' },
        dateTimeOptions : { year: 'numeric', month: 'long', day: 'numeric' , hour: '2-digit', minute:'2-digit' },
      }
  },
  beforeMount() {
    this.refresh();
  },
  methods: {
    refresh: async function () {
      this.loading = true;

      var base = this.fromDate;
      var noOfDays = Math.round((Number(this.toDate) - Number(this.fromDate))/24/3600/1000);
      // var oneDay = 24 * 3600 * 1000;
      var dates = [];
      var budgetData = [];
      var expectedData = [];
      var usedData = [];

      await this.getAggregatedData(base, noOfDays, dates, budgetData, expectedData, usedData)

      let usedBudget = Math.round(usedData[usedData.length - 1] * 100) / 100;
      let usedHours = Math.round((usedBudget / this.normalRate) * 100) / 100;
      this.topBoxData = {
        totalAllocatedBudget: this.totalAllocatedBudget,
        totalAllocatedHours: this.totalAllocatedHours,
        usedBudget: usedBudget,
        usedHours: usedHours,
        remainingBudget: Math.round((this.totalAllocatedBudget - usedBudget) * 100) / 100,
        remainingHours: Math.round((this.totalAllocatedHours - usedHours) * 100) / 100,
      };

      this.chartData = {
        dates: dates,
        expectedData: expectedData,
        usedData: usedData,
        budgetData: budgetData
      };

      this.lastUpdate = this.formatDate(new Date);
      this.loading = false;
    },
    formatDate: function(jsDate) {
      var months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      var date = jsDate.getDate()+'-'+months[jsDate.getMonth()]+'-'+jsDate.getFullYear();
      var hours = jsDate.getHours();
      var minutes = jsDate.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      return date+' ' + hours + ':' + minutes + ' ' + ampm;
    },
    getAggregatedData: function (base, noOfDays, dates, budgetData, expectedData, usedData) {
        var api = new SupportPuppyApi();
        return api.updateData(base, noOfDays, dates, budgetData, expectedData, usedData);
    }
  },
};
</script>

<style>

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }

  .update-date {
    display: flex;
    margin: auto;
    width: 100%;
    justify-content: space-around;
  }

  .update-btn {
    border: 1px solid;
    text-decoration: none;
    color: white;
    background: #F7BA87;
    text-transform: uppercase;
    padding: .7rem;
    border-radius: 10px;
    font-size: 13px;
  }

  .date-option {
    padding: 10px;
    text-transform: uppercase;
    border-color: #8dcff7;
  }

  .option {
    margin-top: 1.5rem;
  }

  .data-as-of {
    margin-right: 20px;
  }

  @media only screen and (max-width: 480px) {
    .update-date {
      display: block;
    }

    .latest-date {
      margin-bottom: 1rem;
    }
  }
</style>
