<template>
  <div class="chart-echart">
  <v-chart :options="options" class="chart"></v-chart>
  </div>
</template>

<script>

import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/title'

export default {
  name: 'SupportChart',
  components: {
    'v-chart': ECharts
  },
  props: {
    chartData: Object
  },
  data () {
    var options = {
      title: {
        text: 'Support Puppy'
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['Budget', 'Expected', 'Used']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {
            title: 'Save As picture',
          },
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: this.chartData.dates
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'Budget',
          type: 'line',
          stack: 'Budget',
          data: this.chartData.budgetData
        },
        {
          name: 'Expected',
          type: 'line',
          stack: 'Expected',
          data: this.chartData.expectedData
        },
        {
          name: 'Used',
          type: 'line',
          stack: 'Used',
          data: this.chartData.usedData
        },
      ]
    };

    return {
      options: options
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
   .chart {
     margin: 0 auto;
   }

  .chart-echart {
    overflow: scroll;
  }
</style>
