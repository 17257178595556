import Axios from 'axios';

export default class supportPuppyAPI {
	contactId = 202009;
	entries = [];
	token = false;

	constructor() {

		let params = new URLSearchParams(window.location.search.substring(1));
		this.token = params.get("token") || false;
		let base_url = "https://api-proxy.eightyoptions.com/index.php";
        //let base_url = "http://localhost:8080";

		if (this.token !== false) {
			localStorage.setItem('api-token', this.token)
		} else {
            this.token = localStorage.getItem('api-token') || false
		}

		if (this.token === false) {
			console.error("No valid token found");
		}

		this.axios = Axios.create({
			baseURL: `${base_url}`,
			// headers: {
			// 	// Authorization: `Bearer ${token}`,
			// },
		})
	}

	async updateData(startDate, noOfDays, dates, budgetData, expectedData, usedData) {
		let endDate = new Date(startDate.valueOf());
		endDate.setDate(endDate.getDate() + noOfDays); //This gets the day of the month, then increments. Handles month incrementing.
		let path = '';
		let options = {
			params: {
				api_key: this.token,
				from: startDate.toLocaleString('en-AU'),
				to: endDate.toLocaleString('en-AU'),
				offset: 0,
				users: "all",
				contacts: "202009",
                csurl: `https://minutedock.com/api/v1/entries`,
			}
		};

		this.entries = [];

		await this.getDataFromMinuteDock(
			path,
			options,
		).then(() => {

			var item;
			var dayData = [];
			for (var i = 0; i < this.entries.length; i++) {
                item = this.entries[i];
                if (typeof dayData[item.date] == 'undefined') {
                    dayData[item.date] = 0;
                }

                // Check for urgent task.
                if (item.task_ids.includes(73676)) { // Urgent task id.
                    dayData[item.date] = dayData[item.date] + (parseFloat(item.duration_hours) * 217.5); // Urgent rate.
                }
                else {
                    dayData[item.date] = dayData[item.date] + (parseFloat(item.duration_hours) * 145); // Standard rate.
                }

//                results.total_hours = results.total_hours + parseFloat(item.duration_hours);
            }

			var accum = 0;
            for (i = 0; i < noOfDays; i++) {
                var nextDay = new Date(startDate);
                nextDay.setDate(nextDay.getDate() + i);
                var day = [nextDay.getFullYear(), this.pad(nextDay.getMonth() + 1,2), this.pad(nextDay.getDate(),2)].join('-');
                dates.push(day);
                budgetData.push(15590);
				expectedData.push(Math.round(((15590 / noOfDays) * i) * 100) / 100);

				if (dayData[day] !== undefined) {
                    accum = accum + dayData[day];
				}
                usedData.push(accum);
            }
		});

	}

	async getDataFromMinuteDock(path, options) {
		await this.axios.get(path, options).then(
			async (response) => {

				let data = response.data;
				data.forEach(entry => {
					if (entry.contact_id == this.contactId) { //CCAMLR
						// Filter out project work.
						this.entries.push(entry);
					}
				});

				// Recursively call until results in page is < 50.
				if (data.length >= 50) {
					options.params.offset += data.length;
					await this.getDataFromMinuteDock(path, options);
				}

			},
			(error) => {
				console.log("Going offline");
				this.isOnline = false;
				console.log(`Error updating reference data for ${path}: ${error}`)
			}
		);
	}

    pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

}